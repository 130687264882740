import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
const router = new Router({
  // export default new Router({
  mode: "history",
  base: "/",
  routes: [
    {
      path: "/",
      name: "/",
      redirect: "/Login",
    },

    // 菜单页面
    {
      path: "/Home",
      name: "/Home",
      component: () => import("./components/Home.vue"),
      children: [
        // 首页
        {
          path: "/index",
          name: "首页",
          component: () => import("./components/system/Index.vue"),
        },

        // 角色管理
        {
          path: "/system/role",
          name: "角色管理",
          component: () => import("./components/system/RoleManage.vue"),
        },
        // 账户管理
        {
          path: "/system/account",
          name: "账户管理",
          component: () => import("./components/system/AccontManage.vue"),
        },
        // 修改密码页
        {
          path: "/system/changePwd",
          name: "修改密码",
          component: () => import("./components/system/Editpsw.vue"),
        },
        // 表格页
        {
          path: "/table",
          name: "table",
          component: () => import("./components/system/Table.vue"),
        },
        // 设备信息
        {
          path: "/deviceInfo",
          name: "设备信息",
          component: () => import("./components/system/DevManager.vue"),
        },
        // 开关
        {
          path: "/switchControl",
          name: "开关控制",
          component: () => import("./components/system/SwitchControl.vue"),
        },
        // 报警管理
        {
          path: "/alarmManage",
          name: "报警管理",
          component: () => import("./components/system/Alarm.vue"),
        },
        // 摄像头管理
        {
          path: "/deviceConfig/camera",
          name: "摄像头配置",
          component: () => import("./components/system/MonitorManage.vue"),
        },
        // 3d模型管理
        {
          path: "/deviceConfig/threeDimensional",
          name: "3d配置",
          component: () => import("./components/system/3dManage.vue"),
        },
        // 图片配置
        {
          path: "/deviceConfig/photo",
          name: "图片配置",
          component: () => import("./components/system/UploadImage.vue"),
        },
      ],
    },
    // 登录页
    {
      path: "/Login",
      name: "/Login",
      component: () => import("./components/Login.vue"),
    },
    // 忘记密码页
    {
      path: "/Forget",
      name: "/Forget",
      component: () => import("./components/system/ForgetPsw.vue"),
    },
    // 地图页
    {
      path: "/map",
      name: "map",
      component: () => import("./components/dataDrive/Map.vue"),
    },
    // 城市设备页
    {
      path: "/Citydevice",
      name: "Citydevice",
      component: () => import("./components/dataDrive/CityDevice.vue"),

    },
    // 更多模块页
    {
      path: "/ModelList",
      name: "ModelList",
      component: () => import("./components/dataDrive/ModelList.vue"),

    },
    // 模块详情页
    {
      path: "/ModelDetail",
      name: "ModelDetail",
      component: () => import("./components/dataDrive/ModelDetail.vue"),

    },
    // 在线设备参数详情
    {
      path: "/detail",
      name: "detail",
      component: () => import("./components/dataDrive/MoreDetail.vue"),
    },
    // 图片查看
    {
      path: "/imglook",
      name: "imglook",
      component: () => import("./components/dataDrive/ImageLook.vue"),
    },
    // 设备监控
    {
      path: "/monitor",
      name: "monitor",
      component: () => import("./components/dataDrive/Monitor.vue"),
    },
    // 3d展示
    {
      path: "/3DShow",
      name: "3DShow",
      component: () => import("./components/dataDrive/3DShow.vue"),
    },
    // // 安卓3d页面
    // {
    //   path: "/3D",
    //   name: "3D",
    //   component: () => import("./components/3d.vue"),
    // },
    // AI分类
    {
      path: "/AIClass",
      name: "AIClass",
      component: () => import("./components/dataDrive/AIClass.vue"),
    },
    {
      path: "/AI",
      name: "AI",
      component: () => import("./components/dataDrive/AI.vue"),
    },
    {
      path: "/AIData",
      name: "AIData",
      component: () => import("./components/dataDrive/AIData.vue"),
    },
    // 地图带散点图功能
    {
      path: "/dotMap",
      name: "dotMap",
      component: () => import("./components/dataDrive/dotMap.vue"),
    },
    // 图片放大功能
    {
      path: "/swiper",
      name: "swiper",
      component: () => import("./components/swiper.vue"),
    },
    // 图片放大功能（缩略图）
    {
      path: "/zoom",
      name: "zoom",
      component: () => import("./components/zoom.vue"),
    },
    // 放大镜功能
    {
      path: "/mirror",
      name: "mirror",
      component: () => import("./components/mirror.vue"),
    },
  ],
});
router.beforeEach((to, from, next) => {
  if (to.meta.refresh) {
    // 如果路由的meta字段中包含refresh属性，表示需要刷新页面

    console.log('11')
    next()
    window.location.reload();
  } else {
    next() // 正常路由跳转
  }
})
export default router
// 以下代码解决路由地址重复的报错问题(一劳永逸)
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
